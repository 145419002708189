import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';
import { available_fonts } from '../../../../../utils/fonts/fonts';
import { useDispatch, useSelector } from 'react-redux';
import { selectVCardData, vCardDataDesign, vCardData as vCardSetup } from '../../../../../redux/action/cardData';
import { createCardColor } from '../../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
export default function CardDesing() {
  const dispatch = useDispatch();
  const vCardData = useSelector(selectVCardData)
  const [primaryColor, setPrimaryColor] = useState(vCardData?.primaryColor);
  const [profileTextColor, setProfileTextColor] = useState(vCardData?.profileTextColor);
  const [secondaryColor, setSecondaryColor] = useState(vCardData?.secondaryColor)

  const [fontFamily, setFontFamily] = useState(vCardData?.fontFamily)
  const [titleFontSize, setTitleFontSize] = useState(vCardData?.titleFontSize)


  const handleSubmitForm = () => {
    const body = {
      "cardId": "645f50fc9284c30000a46c44",
      "primaryColor": primaryColor,
      "secondaryColor": secondaryColor,
      "profileTextColor": profileTextColor,
      "font": fontFamily,
      "fontSize": titleFontSize
    }

    toast.promise(createCardColor(body), {
      loading: <span>Updating....</span>,
      success: <span>Update Successfully</span>,
      error: <span>Something went wrong!</span>
    }).then((response) => {
      // console.log(response);
    }).catch((error) => console.log(error))
  }

  useEffect(() => {
    dispatch(vCardDataDesign({ primaryColor, profileTextColor, secondaryColor, fontFamily, titleFontSize }))
  }, [primaryColor, profileTextColor, secondaryColor, fontFamily, titleFontSize])

  return (
    <div className="card">
      <div className="card-body">
        <div className="preview-block">
          <p>Color</p>
          <div className="row gy-4 mb-4">
            <div className="col-sm-5">
              <div className="form-group">
                <label className="form-label" htmlFor='primary-color'>
                  Primary Color
                </label>
                <div className="color-picker-form-input">
                  <label htmlFor='primary-color'>
                    <div className="form-control">{primaryColor}</div>
                    <input type='color' id='primary-color' value={primaryColor} onChange={(event) => setPrimaryColor(event.target.value)} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4 mb-4">
            <div className="col-sm-5">
              <div className="form-group">
                <label className="form-label" htmlFor='profile-text-color'>
                  Profile text color
                </label>
                <div className="color-picker-form-input">
                  <label htmlFor='profile-text-color'>
                    <div className="form-control">{profileTextColor}</div>
                    <input type='color' id='profile-text-color' value={profileTextColor} onChange={(event) => setProfileTextColor(event.target.value)} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4 mb-4">
            <div className="col-sm-5">
              <div className="form-group">
                <label className="form-label" htmlFor='secondary-color'>
                  Secondary color
                </label>
                <div className="color-picker-form-input">
                  <label htmlFor='secondary-color'>
                    <div className="form-control">{secondaryColor}</div>
                    <input type='color' id='secondary-color' value={secondaryColor} onChange={(event) => setSecondaryColor(event.target.value)} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p>Font Style</p>
          {/* <div className="row gy-4 mb-4">
            <div className="col-sm-5">
              <div className="form-group">
                <label className="form-label" htmlFor='primary-color'>
                  Font Family
                </label>
                <div className='form-control-wrap'>
                  <select className='form-control' onChange={(event) => setFontFamily(event.target.value)}>
                    {available_fonts?.map((item, index) => <option value={item.value}>{item.name}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row gy-4 mb-4">
            <div className="col-sm-5">
              <div className="form-group">
                <label className="form-label" htmlFor='primary-color'>
                  Title font size
                </label>
                <div className='form-control-wrap'>
                  <input placeholder='ex: 18px' className='form-control' value={titleFontSize} onChange={(event) => setTitleFontSize(event.target.value)} />
                  <small></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
