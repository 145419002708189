import React, { useEffect, useState } from "react";
import ParseJwt from "./../../services/parseJwt/parseJwt";
import { Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { LOGO } from "../../utils/images/Images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profile, selectProfile } from "../../redux/action/profileData";
import { getProfileById } from "../../services/api/api-services";

export default function Header({ isAuth }) {
    const [userData, setUserData] = useState();
    const profileData = useSelector(selectProfile)
    const dispatch = useDispatch()
    const decodedToken = () => {
        const decoded = ParseJwt(localStorage.getItem("token"));
        setUserData(decoded?._id);
        handleUserProfile(decoded?._id);
    };
    const handleLogout = () => {
        localStorage.clear();

        toast.success("Logout successfully!");
        isAuth && isAuth();
    };
    useEffect(() => {
        decodedToken();
    }, []);

    const handleSideBar = () => {

    };

    // handle user profile data in redux store;
    const handleUserProfile = (userId) => {
        getProfileById(userId?._id).then((response) => {
            if (response?.err === false) {
                dispatch(profile(response?.user))

            } else {
                toast.success(response?.msg);
            }
        }).catch(err => console.log(err))
    }


    return (
        <div className="nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ms-n1">
                        <a
                            id="headerButton"
                            className="nk-nav-toggle nk-quick-nav-icon"
                        >
                            <em className="icon ni ni-menu" />
                        </a>
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <a href="index.html" className="logo-link">
                        <h4 className="text-dark logo-img">Card Connect</h4>
                        <h4 className="text-white logo-img">Card Connect</h4>
                           
                        </a>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="">
                                <Dropdown className="user-dropdown">
                                    <Dropdown.Toggle
                                        as={"a"}
                                        variant=""
                                        id="dropdown-basic"
                                        className="user-toggle"
                                    >
                                        <div className="user-avatar sm">
                                            {profileData?.image ? <img src={profileData?.image} style={{ width: 32, height: 32, borderRadius: 32, objectFit: 'cover' }} /> : <em className="icon ni ni-user-alt" />}
                                        </div>
                                        <div className="user-info d-none d-md-block">
                                            <div className="user-status">Administrator</div>
                                            <div className="user-name dropdown-indicator">
                                                {profileData?.firstName} {profileData?.lastName}
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <div className="user-card">
                                                <div className="user-avatar">
                                                    <span>
                                                        {profileData?.firstName?.charAt(0)}
                                                        {profileData?.lastName?.charAt(0)}
                                                    </span>
                                                </div>
                                                <div className="user-info">
                                                    <span className="lead-text">
                                                        {profileData?.firstName} {profileData?.lastName}
                                                    </span>
                                                    <span className="sub-text">{profileData?.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li>
                                                    <Link to={'profile'}>
                                                        <em className="icon ni ni-user-alt" />
                                                        <span>View Profile</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a href="user-profile-setting.html">
                                                        <em className="icon ni ni-setting-alt" />
                                                        <span>Account Setting</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="user-profile-activity.html">
                                                        <em className="icon ni ni-activity-alt" />
                                                        <span>Login Activity</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown-inner">
                                            <ul className="link-list">
                                                <li>
                                                    <a className="cursor-pointer" onClick={handleLogout}>
                                                        <em className="icon ni ni-signout" />
                                                        <span>Sign out</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            {/* <li className="dropdown notification-dropdown me-n1"><a href="#" className="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown">
                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" />
                                </div>
                            </a>
                                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-end dropdown-menu-s1">
                                    <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                    <div className="dropdown-body">
                                        <div className="nk-notification">
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit
                                                        Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit
                                                        Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">You have requested to
                                                        <span>Widthdrawl</span>
                                                    </div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                            <div className="nk-notification-item dropdown-inner">
                                                <div className="nk-notification-icon"><em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                                                </div>
                                                <div className="nk-notification-content">
                                                    <div className="nk-notification-text">Your <span>Deposit
                                                        Order</span> is placed</div>
                                                    <div className="nk-notification-time">2 hrs ago</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
