export const SOCIAL_MEDIA = {
    Facebook: 1,
    Instagram: 2,
    Twitter: 3,
    LinkedIn: 4,
    TikTok: 5,
    SnapChat: 6,
    Whatsapp: 7,
    Telegram: 8,
    Vimeo: 9,
    Youtube: 10,
    Wistia: 11,
    Twitch: 12,
    Discord: 13,
    Pinterest: 14,
    Yelp: 15,
    Paypal: 16,
    Venmo: 17,
    CashApp: 18,
    Calendly: 19,
    Shopify: 20,
    Github: 21,
    Dribbble: 22,
    Behance: 23,
    CustomUrl: 24
}


export const socialMediaNamesArray = ['facebook', 'instagram', 'twitter', 'linkedin', 'tiktok', 'snapchat', 'whatsapp', 'telegram', 'vimeo', 'youtube', 'wistia', 'twitch', 'discord', 'pinterest', 'yelp', 'paypal', 'venmo', 'cashapp', 'calendly', 'shopify', 'github', 'dribbble', 'behance', 'custom_url']


export const socialMediaMetaData = [
    {
        id: 1,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/wgu1uyyzwjh7krg6oo4z.png",
        title: 'Facebook'
    },
    {
        id: 2,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yseji32pybmfcygiqhes.png",
        title: 'Instagram'
    },
    {
        id: 3,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/kyfx3lyfwolnogh4f6uj.png",
        title: 'Twitter'
    },
    {
        id: 4,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zpty4iyd35hpns5ciwn6.png",
        title: 'LinkedIn'
    },
    {
        id: 5,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/rzctarbbiajqhvytciqq.png",
        title: 'TikTok'
    },
    {
        id: 6,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/dnbpdtucsaucr6c8jvri.png",
        title: 'Snapchat'
    },
    {
        id: 7,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/vth5rytxkvc39foxv59e.png",
        title: 'Whatsapp'
    },
    {
        id: 8,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/onvjx5ole5iuzdvjkmwd.png",
        title: 'Telegram'
    },
    {
        id: 9,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/frp7yf43efx0mqblcyok.png",
        title: 'Vimeo'
    },
    {
        id: 10,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/rcebhhnu2xfrh13pad70.png",
        title: 'Youtube'
    },
    {
        id: 11,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/h4vbtwntsnmtneqk0gnb.png",
        title: 'Wistia'
    },
    {
        id: 12,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/hurwb0wbtfnbvrvp3rb5.png",
        title: 'Twitch'
    },
    {
        id: 13,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309311/xo8jcqdzioe6cbdu0rcn.png",
        title: 'Discord'
    },
    {
        id: 14,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/x8v7fkpldsw9yh2vsoax.png",
        title: 'Pinterest'
    },
    {
        id: 15,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jvvycr4859etuydzl6gx.png",
        title: 'Yelp'
    },
    {
        id: 16,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/on0dfbufj2nbzzwinmda.png",
        title: 'Paypal'
    },
    {
        id: 17,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/pgjzkcf3sjd5uqrswdbh.png",
        title: 'Venmo'
    },
    {
        id: 18,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/ksoulgpvqkgenbnvybgt.png",
        title: 'CashApp'
    },
    {
        id: 19,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jhitxe1h7ztuqk2a2yu3.png",
        title: 'Calendly'
    },
    {
        id: 20,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/iwis3c9mg1essjd5t1v8.png",
        title: 'Shopify'
    },
    {
        id: 21,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zniuwkqpi65lxtsr8ykj.png",
        title: 'Github'
    },
    {
        id: 22,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yt9o9hxfroryqdhgar8i.png",
        title: 'Dribbble'
    },
    {
        id: 23,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/tueqxmfwg0qxyg37yf8w.png",
        title: 'Behance'
    },
    {
        id: 24,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/n3irrmistg4nnkhgrrqg.png",
        title: 'Custom URL'
    },
]

// export const socialMediaMetaData = [
//     {
//         id: 1,
//         logo: require('../../assets/images/vcard-plus-social-icons/facebook_icon.png'),
//         title: 'Facebook'
//     },
//     {
//         id: 2,
//         logo: require('../../assets/images/vcard-plus-social-icons/instagram_icon.png'),
//         title: 'Instagram'
//     },
//     {
//         id: 3,
//         logo: require('../../assets/images/vcard-plus-social-icons/twitter_icon.png'),
//         title: 'Twitter'
//     },
//     {
//         id: 4,
//         logo: require('../../assets/images/vcard-plus-social-icons/linkedin_icon.png'),
//         title: 'LinkedIn'
//     },
//     {
//         id: 5,
//         logo: require('../../assets/images/vcard-plus-social-icons/tiktok_icon.png'),
//         title: 'TikTok'
//     },
//     {
//         id: 6,
//         logo: require('../../assets/images/vcard-plus-social-icons/snapchat_icon.png'),
//         title: 'Snapchat'
//     },
//     {
//         id: 7,
//         logo: require('../../assets/images/vcard-plus-social-icons/whatsapp_icon.png'),
//         title: 'Whatsapp'
//     },
//     {
//         id: 8,
//         logo: require('../../assets/images/vcard-plus-social-icons/telegram.png'),
//         title: 'Telegram'
//     },
//     {
//         id: 9,
//         logo: require('../../assets/images/vcard-plus-social-icons/Vimeo.png'),
//         title: 'Vimeo'
//     },
//     {
//         id: 10,
//         logo: require('../../assets/images/vcard-plus-social-icons/youtube_icon.png'),
//         title: 'Youtube'
//     },
//     {
//         id: 11,
//         logo: require('../../assets/images/vcard-plus-social-icons/Vistea.png'),
//         title: 'Wistia'
//     },
//     {
//         id: 12,
//         logo: require('../../assets/images/vcard-plus-social-icons/Twitch.png'),
//         title: 'Twitch'
//     },
//     {
//         id: 13,
//         logo: require('../../assets/images/vcard-plus-social-icons/Discord.png'),
//         title: 'Discord'
//     },
//     {
//         id: 14,
//         logo: require('../../assets/images/vcard-plus-social-icons/pinterest_icon.png'),
//         title: 'Pinterest'
//     },
//     {
//         id: 15,
//         logo: require('../../assets/images/vcard-plus-social-icons/Yelp.png'),
//         title: 'Yelp'
//     },
//     {
//         id: 16,
//         logo: require('../../assets/images/vcard-plus-social-icons/Paypal.png'),
//         title: 'Paypal'
//     },
//     {
//         id: 17,
//         logo: require('../../assets/images/vcard-plus-social-icons/Venmo.png'),
//         title: 'Venmo'
//     },
//     {
//         id: 18,
//         logo: require('../../assets/images/vcard-plus-social-icons/Cashapp.png'),
//         title: 'CashApp'
//     },
//     {
//         id: 19,
//         logo: require('../../assets/images/vcard-plus-social-icons/Calendly.png'),
//         title: 'Calendly'
//     },
//     {
//         id: 20,
//         logo: require('../../assets/images/vcard-plus-social-icons/shopify.png'),
//         title: 'Shopify'
//     },
//     {
//         id: 21,
//         logo: require('../../assets/images/vcard-plus-social-icons/Github.png'),
//         title: 'Github'
//     },
//     {
//         id: 22,
//         logo: require('../../assets/images/vcard-plus-social-icons/Dribbble.png'),
//         title: 'Dribbble'
//     },
//     {
//         id: 23,
//         logo: require('../../assets/images/vcard-plus-social-icons/Behance.png'),
//         title: 'Behance'
//     },
//     {
//         id: 24,
//         logo: require('../../assets/images/vcard-plus-social-icons/URL.png'),
//         title: 'Custom URL'
//     },
// ]
