import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOGO } from '../../../utils/images/Images'

export default function Success({ isAuth }) {
    const navigation = useNavigate();

    const handleGoToDashboard = () => {
        const token = localStorage.getItem("token")
        if (token !== null) {
            isAuth && isAuth();
            navigation('/')
        }
    }
    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content'>
                <div className='nk-block nk-block-middle nk-auth-body wide-xs'>
                    <div className="brand-logo pb-4 text-center">
                        <div onClick={handleGoToDashboard} className="logo-link">
                            {/* <img className="logo-light logo-img logo-img-lg" src={LOGO} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LOGO} alt="logo-dark" /> */}
                            <h2 className="text-dark logo-img">Card Connect</h2>
                            <h2 className="text-white logo-img">Card Connect</h2>

                        </div>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h4 className="nk-block-title">Thank you for submitting form</h4>
                                    <div className="nk-block-des text-success mb-5">
                                        <p>You can now sign in with your new password</p>
                                    </div>
                                    <button onClick={handleGoToDashboard} className='btn btn-primary btn-block'>Go to dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
