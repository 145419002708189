import React, { useLayoutEffect, useRef } from "react";
import { LOGO } from "../../utils/images/Images";
import { Link } from "react-router-dom";


const MenuItem = ({ data, onCloseSidebar }) => {
    const submenu = useRef(null);
    const handleSubMenu = (content) => {
        if (submenu.current.classList.contains("active")) {
            submenu.current.classList.remove("active");
        } else {
            submenu.current.classList.add("active");
        }
    };
    const linkProps = data?.isSubMenu ? {} : { to: data?.route };

    return (
        <li
            ref={submenu}
            className={`nk-menu-item ${data?.isSubMenu ? "has-sub" : ""}`}
            onClick={handleSubMenu}
        >
            <Link
                {...linkProps}
                onClick={data?.function}
                className={`nk-menu-link ${data?.isSubMenu ? "nk-menu-toggle cursor-pointer" : ""
                    }`}
            >
                <span className="nk-menu-icon">
                    <em className={`icon ni ${data?.icon}`} />
                </span>
                <span className="nk-menu-text">{data?.name}</span>
            </Link>
            {data?.isSubMenu && (
                <ul className="nk-menu-sub">
                    {data?.subMenuItem?.map((item, index) => (
                        <li className="nk-menu-item" key={index.toString()}>
                            <Link to={item?.route} className="nk-menu-link" onClick={item?.function}>
                                <span className="nk-menu-text">{item?.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};


export default function Sidebar() {

    const LINKS = [
        {
            id: 4,
            name: "My Business Card",
            icon: "ni-cards",
            isSubMenu: true,
            subMenuItem: [
                {
                    id: "1",
                    name: "My Cards",
                    route: "my-cards",
                    function: () => handleSideBar()
                },
            ],
        },
        
        {
            id: 1,
            name: "Analytics",
            route: "/",
            icon: "ni-grid-alt",
            isSubMenu: false,
            function: () => handleSideBar()
        },
    ];

    const handleSideBar = () => {
        if (window.innerWidth < 500) {
            document.body.classList.remove("nav-show")
            document.querySelector(".nk-sidebar").classList.remove("nk-sidebar-active");
            document.querySelector('.nk-sidebar-overlay').remove()
            document.querySelector('.nk-sidebar-overlay').remove()
        }
    }

    return (
        <div className="nk-sidebar nk-sidebar-fixed is-light">
            <div className="nk-sidebar-element nk-sidebar-head">
                {/* Sidebar close button start */}

                <div className="nk-menu-trigger">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                        id="sidebarButton"
                    >
                        <em className="icon ni ni-arrow-left" />
                    </a>
                    {/* <a
                        id="sidebarButton"
                        className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
                    >
                        <em className="icon ni ni-menu" />
                    </a> */}
                </div>
                {/* Sidebar close button end */}
                <div className="nk-sidebar-brand">
                    <Link to={"/"} className="logo-link nk-sidebar-logo">
                        
                        <h4 className="text-dark logo-img">Card Connect</h4>
                        <h4 className="text-white logo-img">Card Connect</h4>
                        
                    </Link>
                </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-body">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu">
                        <ul className="nk-menu">
                            {LINKS?.map((item, index) => (
                                <MenuItem
                                    key={index.toString()}
                                    data={item}
                                    onCloseSidebar={handleSideBar}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
