import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteCard, getCardById, getMyCards, getTemplateById } from '../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import ParseJwt from '../../../../services/parseJwt/parseJwt';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';
import { vCardData, vCardDataAdditional, vCardDataDesign } from '../../../../redux/action/cardData'
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
import Output from './../digital-business-card-output/Output';
import { vCardData as vCardSetup } from '../../../../redux/action/cardData'
import { PREVIEW_CARD_URL } from '../../../../utils/strings/Strings';
export default function MyCardTable() {
    const [cards, setCards] = useState([]);
    const [totalLength, setTotalLength] = useState(0)
    const [perPage, setParPage] = useState(10);
    const [offset, setOffset] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isEmpty, setEmpty] = useState(false)
    const dispatch = useDispatch()
    const [isOutputShow, setOutput] = useState(false)
    const [template, setTemplate] = useState([])
    const [previewCardId, setPreviewCardId] = useState("")
    const [viewCount, setView] = useState(0)
    const getUserId = () => {
        let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
        handleGetMyCard(decodedToken?._id._id)
    }

    const handleGetMyCard = (userId) => {
        getMyCards(userId).then((response) => {
            if (response?.err === false) {
                if (response?.card?.length !== 0) {
                    setCards(response?.card);
                    setTotalLength(response?.card?.length)
                    setEmpty(false)
                } else {
                    setEmpty(true)
                }
            } else {
                toast.error("Something went wrong!")
            }

        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        getUserId();
        return () => {
            handleReduxStateInitial()
        }
    }, []);


    const handlePageChange = (pageNumber) => {
        if (totalLength > activePage * perPage) {
            setActivePage(pageNumber);
            setOffset(pageNumber * perPage)
        }
    }

    const handleReduxStateInitial = () => {
        dispatch(vCardData({
            firstName: "",
            lastName: "",
            prefix: "",
            suffix: "",
            designation: "",
            department: "",
            pronouns: "",
            company: "",
            summary: "",
            phones: [],
            emails: [],
            website: [],
            customLinks: [],
            socialMediaLinks: [],
            location: "",
            userImage: null,
            logo: null,
            logoImagePreview: "",
            userImagePreview: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        }))
        dispatch(vCardDataAdditional({
            isDownload: false,
            isLocation: false
        }))
        dispatch(vCardDataDesign({
            primaryColor: "",
            profileTextColor: "",
            secondaryColor: "",
            fontFamily: "",
            titleFontSize: "",
        }))
    }

    const handleCardDelete = (cardId) => {
        const toastId = toast.loading("Deleting....")
        deleteCard(cardId).then((response) => {
            if (response?.err === false) {
                toast.success(response?.msg);
                toast.dismiss(toastId);
                getUserId()
            } else {
                toast.error(response?.msg);
                toast.dismiss(toastId)
            }
        }).catch((err) => console.log(err));
    }

    const Tooltips = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id} > {title}</Tooltip>}>
            <div>{children}</div>
        </OverlayTrigger>
    );

    //write css in the header

    const handleWriteCss = (css) => {
        let head = document.head || document.getElementsByTagName('head')[0]
        let style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    }
    // get template or layout by id
    const handleGetTemplateById = (layoutId) => {
        getTemplateById(layoutId).then((response) => {
            if (response?.err === false) {
                setTemplate(response?.template)
                handleWriteCss(response?.template?.css);
                setOutput(true)
            } else {
                toast.error("Card template not loaded!")
            }

        }).catch((err) => console.log(err))
    }

    const convertJson = (json) => {
        return json && JSON.parse(json);
    };

    const updateReduxStore = async (response) => {
        dispatch(vCardSetup({
            firstName: response?.card?.firstName,
            lastName: response?.card?.lastName,
            prefix: response?.card?.prefix,
            suffix: response?.card?.suffix,
            designation: response?.card?.designation,
            department: response?.card?.department,
            pronouns: response?.card?.pronouns,
            company: response?.card?.company,
            summary: response?.card?.summery,
            location: response?.card?.location,
            logoImagePreview: response?.card?.logo,
            userImagePreview: response?.card?.profilePhoto,
            logo: response?.card?.logo,
            userImage: response?.card?.profilePhoto,
            phones: convertJson(response?.card?.phones),
            emails: convertJson(response?.card?.emails),
            website: convertJson(response?.card?.webs),
            customLinks: convertJson(response?.card?.customFields),
            socialLinks: convertJson(response?.card?.socialLink),
        }));
        dispatch(vCardDataDesign({
            primaryColor: response?.card?.primaryColor,
            profileTextColor: response?.card?.profileTextColor,
            secondaryColor: response?.card?.secondaryColor,
            fontFamily: response?.card?.font,
            titleFontSize: response?.card?.fontSize
        }))
        dispatch(vCardDataAdditional({
            isLocation: response?.card?.isLocation,
            isDownload: response?.card?.isDownload,
        }))
        if (response?.card?.templateId) {
            handleGetTemplateById(response?.card?.templateId);
        }

    }

    // handle card data when update card
    const handleGetCardData = useCallback((cardId, views) => {
        setPreviewCardId(cardId)
        setView(views)
        getCardById(cardId).then((response) => {
            if (response?.err === false) {
                // handle get template by id when update        
                handleConvertJson(response)
            } else {
                // console.log(response);
            }
        }).catch((err) => console.log(err))
    }, [])

    const handleConvertJson = async (response) => {
        await updateReduxStore(response)
    }
    // console.log(cards);
    return (
        <div className='container-fluid'>
            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='components-preview  mx-auto'>
                        <div className='nk-block nk-block-lg'>
                            <div className='nk-block-head d-flex align-items-start justify-content-between'>
                                <div className='nk-block-head-content w-60'>
                                    <h4 className='nk-block-title'>My Cards ({totalLength})</h4>
                                    <div className='nk-block-des'>
                                        <p>Create and manage your personal cards here.</p>
                                    </div>
                                </div>
                                <Link to={'/card-layouts'} className='btn btn-primary'>Create card</Link>
                            </div>
                            <div className='card card-bordered card-preview'>
                                <div className='card-inner'>
                                    <div className=' table-responsive'>
                                        <table className='nowrap nk-tb-list nk-tb-ulist '>
                                            <thead>
                                                <tr className='nk-tb-item nk-tb-head'>
                                                    {/* <th className='nk-tb-col nk-tb-col-check'>
                                                    <div className='custom-control custom-control-sm custom-checkbox notext'>
                                                        <input type='checkbox' className='custom-control-input' id='uid' />
                                                        <label className='custom-control-label' htmlFor='uid' />
                                                    </div>
                                                </th> */}
                                                    <th className='nk-tb-col' style={{}}>
                                                        <span className='sub-text'>Name</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Link</span>
                                                    </th>
                                                    {/* <th className='nk-tb-col tb-col-md'>
                                                    <span className='sub-text'>Save</span>
                                                </th> */}
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Views</span>
                                                    </th>
                                                    <th className='nk-tb-col nk-tb-col-tools text-end' />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cards.length > 0 ? cards?.slice(offset, offset + perPage).map((item, index) => (
                                                    <tr className='nk-tb-item'>
                                                        {/* <td className='nk-tb-col nk-tb-col-check'>
                                                        <div className='custom-control custom-control-sm custom-checkbox notext'>
                                                            <input type='checkbox' className='custom-control-input' id='uid1' />
                                                            <label className='custom-control-label' htmlFor='uid1' />
                                                        </div>
                                                    </td> */}
                                                        <td className='nk-tb-col'>
                                                            <div className='user-card'>
                                                                <div className='user-avatar bg-dim-primary'>
                                                                    {item?.profilePhoto ? <img src={item?.profilePhoto} style={{ width: 40, height: 40, maxWidth: "unset", maxHeight: "unset", objectFit: 'cover' }} /> : <span className='text-uppercase'>{item?.firstName?.charAt(0)}{item?.lastName?.charAt(0)}</span>}
                                                                </div>
                                                                <div className='user-info'>
                                                                    <span className='tb-lead'>{item?.firstName} {item?.lastName}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='nk-tb-col'>
                                                            <span className='tb-amount'>{PREVIEW_CARD_URL}{item?._id}</span>
                                                        </td>
                                                        {/* <td className='nk-tb-col tb-col-md'>
                                                        <span>0</span>
                                                    </td> */}
                                                        <td className='nk-tb-col'>
                                                            <span>{item?.views?.viewCount}</span>
                                                        </td>
                                                        <td className='nk-tb-col'>
                                                            <ul className='nk-tb-actions gx-1'>
                                                                <li className='nk-tb-action-hidden'>
                                                                    <Tooltips title={"Info"} id={item?._id}>
                                                                        <div className='btn btn-trigger btn-icon' onClick={() => handleGetCardData(item?._id, item?.views?.viewCount)}>
                                                                            <em className='icon ni ni-info-fill' />
                                                                        </div>
                                                                    </Tooltips>
                                                                </li>
                                                                <li className='nk-tb-action-hidden'>
                                                                    <Tooltips title={"Edit"}>
                                                                        <Link to={`/update/${item?._id}`} state={{ isUpdate: true }} className='btn btn-trigger btn-icon'>
                                                                            <em className='icon ni ni-edit-fill' />
                                                                        </Link>
                                                                    </Tooltips>
                                                                </li>
                                                                <li className='nk-tb-action-hidden'>
                                                                    <Tooltips title={"Show"}>
                                                                        <Link className='btn btn-trigger btn-icon' target='_blank' to={`${PREVIEW_CARD_URL}${item?._id}`}>
                                                                            <em className='icon ni ni-eye-fill' />
                                                                        </Link>
                                                                    </Tooltips>
                                                                </li>
                                                                <li className='nk-tb-action-hidden'>
                                                                    <Tooltips title={"Analytics"}>
                                                                        <Link className='btn btn-trigger btn-icon' to={`/card-analytics/${item?._id}`} state={{ cardData: item }}>
                                                                            <em class="icon ni ni-bar-chart-fill"></em>
                                                                        </Link>
                                                                    </Tooltips>
                                                                </li>
                                                                <li>
                                                                    <div className='drodown'>
                                                                        <a href='#' className='dropdown-toggle btn btn-icon btn-trigger' data-bs-toggle='dropdown'><em className='icon ni ni-more-h' /></a>
                                                                        <div className='dropdown-menu dropdown-menu-end'>
                                                                            <ul className='link-list-opt no-bdr'>
                                                                                {/* <li>
                                                                                <a href='#'><em className='icon ni ni-wallet-fill' /><span>Share wallet pass</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-swap-alt-fill' /><span>Duplicate</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-repeat' /><span>Change owner</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-exchange' /><span>Change organization</span></a>
                                                                            </li>
                                                                            <li className='divider' />
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-template-fill' /><span>Mark as template</span></a>
                                                                            </li> */}
                                                                                <li>
                                                                                    <a href='#' onClick={() => handleCardDelete(item?._id)}><em className='icon ni ni-trash-fill' /><span>Delete</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )) : isEmpty ? <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>No data found!</td></tr> : <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>Loading....</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center mt-4'>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            innerClass='pagination'
                                            itemClass='page-item'
                                            linkClass='page-link'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Output cardId={previewCardId} isShow={isOutputShow} viewCount={viewCount} onClose={() => setOutput(false)} innerHtml={template?.html} />
        </div>

    )
}
