import { createSlice } from "@reduxjs/toolkit";
import { getCardById } from "../../services/api/api-services";

const VCardData = createSlice({
    name: "vCardData",
    initialState: {
        firstName: "",
        lastName: "",
        prefix: "",
        suffix: "",
        designation: "",
        department: "",
        // pronouns: "",
        company: "",
        summary: "",
        phones: [],
        emails: [],
        website: [],
        customLinks: [],
        socialMediaLinks: [],
        location: "",
        userImage: null,
        logo: null,
        logoImagePreview: "",
        userImagePreview: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        primaryColor: "",
        profileTextColor: "",
        secondaryColor: "",
        fontFamily: "",
        titleFontSize: "",
        isDownload: false,
        isLocation: false,
        previewCardId: ""
    },
    reducers: {
        vCardData: (state, action) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.prefix = action.payload.prefix;
            state.suffix = action.payload.suffix;
            state.designation = action.payload.designation;
            state.department = action.payload.department
            // state.pronouns = action.payload.pronouns;
            state.company = action.payload.company;
            state.summary = action.payload.summary;
            state.location = action.payload.location;
            state.logoImagePreview = action.payload.logoImagePreview;
            state.userImagePreview = action.payload.userImagePreview
            state.logo = action.payload.logo
            state.userImage = action.payload.userImage

            const phonesDir = [];
            for (const value in action.payload.phones) {
                phonesDir.push({ value: action.payload.phones[value].value, type: action.payload.phones[value].type })
            }
            state.phones = phonesDir

            const emailDir = [];
            for (const value in action.payload.emails) {
                emailDir.push({ value: action.payload.emails[value].value, type: action.payload.emails[value].type })
            }
            state.emails = emailDir

            const websiteDir = [];
            for (const value in action.payload.website) {
                websiteDir.push({ value: action.payload.website[value].value, type: action.payload.website[value].type })
            }
            state.website = websiteDir

            const customLinksDir = [];
            for (const value in action.payload.customLinks) {
                customLinksDir.push({ value: action.payload.customLinks[value].value, type: action.payload.customLinks[value].type })
            }
            state.customLinks = customLinksDir

            const socialLinks = [];
            for (const value in action.payload.socialLinks) {
                socialLinks.push({ title: action.payload.socialLinks[value].title, link: action.payload.socialLinks[value].link, logo: action.payload.socialLinks[value].logo })
            }
            state.socialMediaLinks = socialLinks;
        },
        vCardDataDesign: (state, action) => {
            state.primaryColor = action.payload.primaryColor;
            state.profileTextColor = action.payload.profileTextColor;
            state.secondaryColor = action.payload.secondaryColor;
            state.fontFamily = action.payload.fontFamily;
            state.titleFontSize = action.payload.titleFontSize;
        },
        vCardDataAdditional: (state, action) => {
            state.isDownload = action.payload.isDownload;
            state.isLocation = action.payload.isLocation;
        },
    },
})



export const { vCardData, vCardDataDesign, vCardDataAdditional, vCardUpdate, vCardPreview } = VCardData.actions;


export const selectVCardData = (state) => state.vCardData;
export const selectVCardDataDesign = (state) => state.vCardDataDesign;

export default VCardData.reducer;

