import React, { useEffect, useState } from "react";
import GoogleMapContainer from "../../../components/googleMap/GoogleMapContainer";
import { getLocationDataByCardId, getTotalCardViewCount } from "../../../services/api/api-services";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import ParseJwt from "../../../services/parseJwt/parseJwt";
import { PREVIEW_CARD_URL } from '../../../utils/strings/Strings';

export default function Analytics() {
    const [markers, setMarkers] = useState([]);
    const [data, setData] = useState([]);
    const [isEmpty, setEmpty] = useState(false)
    const { cardId } = useParams();
    const [perPage, setParPage] = useState(10);
    const [offset, setOffset] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [totalViewCount, setTotalViewCount] = useState(0);
    const { state } = useLocation()
    const handleGetLocationData = (cardId) => {
        getLocationDataByCardId(cardId).then((response) => {
            if (response.err === false) {
                if (response?.card?.length !== 0) {
                    setData(response?.card);
                    setTotalLength(response?.card?.length)
                    setEmpty(false)
                    response?.card?.forEach((element, index) => {
                        element?.location?.forEach((cord) => {
                            if (markers?.length > 0) {
                                if (markers[index].lat !== cord?.longitude) {
                                    markers.push({
                                        lat: cord.longitude,
                                        lng: cord?.latitude
                                    })
                                }
                            } else {
                                markers.push({
                                    lat: cord.longitude,
                                    lng: cord?.latitude,
                                    
                                })
                            }
                        })
                    });
                    setTotalViewCount(response?.count);
                } else {
                    setEmpty(true)
                }
            } else {
                toast.error(response?.msg);
            }
        }).catch((err) => console.log(err))
    }

    const getUserId = () => {
        let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
        handleGetCountView(decodedToken?._id._id)
    }

    const handleGetCountView = (userId) => {
        getTotalCardViewCount(userId).then(response => {
            if (response?.err === false) {
                // console.log(response);
                if (response?.total) {

                }
            } else {

            }
        }).catch(err => console.log(err))
    }

    const [totalLength, setTotalLength] = useState(0);

    useEffect(() => {
        handleGetLocationData(cardId, activePage)
        // getUserId()
    }, [])

    const handlePageChange = (pageNumber) => {
        if (totalLength > activePage * perPage) {
            setActivePage(pageNumber);
            setOffset(pageNumber * perPage)
        }

    }

    return (
        <div className="container-fluid">
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title"></h3>
                            </div>
                            {/* <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand me-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-more-v" />
                                    </a>
                                    <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <div className="drodown">
                                                    <a
                                                        href="#"
                                                        className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <em className="d-none d-sm-inline icon ni ni-calender-date" />
                                                        <span>
                                                            <span className="d-none d-md-inline">Last</span>{" "}
                                                            30 Days
                                                        </span>
                                                        <em className="dd-indc icon ni ni-chevron-right" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                            <li>
                                                                <a href="#">
                                                                    <span>Last 30 Days</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <span>Last 6 Months</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <span>Last 1 Years</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <a href="#" className="btn btn-primary">
                                                    <em className="icon ni ni-reports" />
                                                    <span>Reports</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="row g-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="title"><b>Link</b> : <a href={`${PREVIEW_CARD_URL}${cardId}`} target="_blank">{PREVIEW_CARD_URL}{cardId}</a></div>
                                        {/* <div className="title">Created: {state?.cardData?.createdAt}</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <div className="title">Card View</div>
                                                </div>
                                            </div>
                                            <div className="data">
                                                <div className="data-group">
                                                    <h5 className="amount mt-4">{totalViewCount}</h5>
                                                </div>
                                                {/* <div className="info">
                                                    <span className="change up text-danger">
                                                        <em className="icon ni ni-arrow-long-up" />
                                                        4.63%
                                                    </span>
                                                    <span> vs. last week</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-sm-6">
                                <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <div className="title">Card View</div>
                                                </div>
                                            </div>
                                            <div className="data">
                                                <div className="data-group">
                                                    <h5 className="amount mt-4">1,945</h5>
                                                </div>
                                                <div className="info">
                                                    <span className="change up text-danger">
                                                        <em className="icon ni ni-arrow-long-up" />
                                                        4.63%
                                                    </span>
                                                    <span> vs. last week</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="card">
                                    <div className="nk-ecwg nk-ecwg6">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <div className="title">Card View</div>
                                                </div>
                                            </div>
                                            <div className="data">
                                                <div className="data-group">
                                                    <h5 className="amount mt-4">1,945</h5>
                                                </div>
                                                <div className="info">
                                                    <span className="change up text-danger">
                                                        <em className="icon ni ni-arrow-long-up" />
                                                        4.63%
                                                    </span>
                                                    <span> vs. last week</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* Card View by city */}
                            <div className="col-md-12">
                                <div className="card card-full">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h6 className="title">Card views by city</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className='nk-tb-list nk-tb-ulist' data-auto-responsive='false'>
                                            <thead>
                                                <tr className='nk-tb-item nk-tb-head'>
                                                    {/* <th className='nk-tb-col nk-tb-col-check'>
                                                    <div className='custom-control custom-control-sm custom-checkbox notext'>
                                                        <input type='checkbox' className='custom-control-input' id='uid' />
                                                        <label className='custom-control-label' htmlFor='uid' />
                                                    </div>
                                                </th> */}
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>City</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>State/Province</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Country</span>
                                                    </th>
                                                    <th className='nk-tb-col'>
                                                        <span className='sub-text'>Card&nbsp;Views</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {data?.length > 0 ? data?.slice(offset, offset + perPage)?.map((item, index) => (
                                                <tr className="nk-tb-item">
                                                    <td className="nk-tb-col">
                                                        <span className="tb-lead text-capitalize">{item?.city}</span>
                                                    </td>
                                                    <td className="nk-tb-col">
                                                        <span className="tb-lead text-capitalize">{item?.state}</span>
                                                    </td>
                                                    <td className="nk-tb-col">
                                                        <span className="tb-lead text-capitalize">{item?.region}</span>
                                                    </td>
                                                    <td className="nk-tb-col">
                                                        <span className="tb-lead text-capitalize">{item?.viewCount}</span>
                                                    </td>
                                                </tr>
                                            )) : isEmpty ? <tr><td colSpan={4}><span className="text-center py-3">No data found!</span></td></tr> : <tr><td colSpan={4} className="text-center py-3"><span>Loading...</span></td></tr>}
                                        </table>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center my-4'>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalLength}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            innerClass='pagination'
                                            itemClass='page-item'
                                            linkClass='page-link'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Card Location */}
                            <div className="col-md-12">
                                <div className="card card-full overflow-hidden">
                                    <div className="nk-ecwg nk-ecwg7 h-100">
                                        <div className="card-inner flex-grow-1">
                                            <div className="card-title-group mb-4">
                                                <div className="card-title">
                                                    <h6 className="title">Card views by location</h6>
                                                </div>
                                            </div>
                                            {markers?.length > 0 && <div className="nk-ecwg7-ck" style={{ height: 450 }}>
                                                <GoogleMapContainer markers={markers} />
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
