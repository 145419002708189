import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { LOGO } from '../../../utils/images/Images';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { verifyOTP } from '../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../utils/strings/Strings';
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function VerifyOtp() {
    const navigation = useNavigate();

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [isDisabled, setDisabled] = useState(false)
    let query = useQuery();

    const handleOtpValidation = (event) => {
        event.preventDefault();

        if (otp === "") {
            setOtpError(true);
            return
        } else {
            setOtpError(false);
            handleFormSubmit();
        }
    }

    const handleFormSubmit = () => {
        let body = {
            "email": query.get("email"),
            "OTP": otp,
        }

        const toastId = toast.loading("Loading...")
        setDisabled(true)
        verifyOTP(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg)
                navigation(`/reset-password?email=${query.get("email")}`)
                setDisabled(false)
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg)
                setDisabled(false)
            }
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        if (!query.get("email")) {
            navigation('/')
        }
    }, [])
    useEffect(() => {
        if (otp.length === 4) {
            handleFormSubmit()
        }
    }, [otp])

    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content'>
                <div className='nk-block nk-block-middle nk-auth-body wide-xs'>
                    <div className="brand-logo pb-4 text-center">
                        <Link to={'/'} className="logo-link">
                            {/* <img className="logo-light logo-img logo-img-lg" src={LOGO} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LOGO} alt="logo-dark" /> */}
                            <h2 className="text-dark logo-img">Card Connect</h2>
                            <h2 className="text-white logo-img">Card Connect</h2>

                        </Link>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className="nk-block-head">
                                <form className="nk-block-head-content" onSubmit={handleOtpValidation}>
                                    <h4 className="nk-block-title">Enter verification code</h4>
                                    <p className='mb-0'>We have sent you <b>One Time Password</b> to your email</p>
                                    {/* <p className='text-primary'>Please Enter OTP</p> */}
                                    <p className='mb-4'><b>{query.get("email")}</b></p>
                                    <div className='form-group'>
                                        <div className='form-control-wrap'>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                containerStyle={{ justifyContent: 'center' }}
                                                inputStyle={otpError ? errorStyle : {}}
                                                shouldAutoFocus
                                                renderInput={(props) => <input {...props} className='form-control otpinputStyle p-0' />}
                                            />
                                            {otpError && <small className='invalid-feedback d-block'>OTP must be filled out.</small>}
                                        </div>
                                    </div>
                                    <button type='submit' disabled={isDisabled} className='btn btn-primary btn-block'>Verify OTP</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
