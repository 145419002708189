import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LOGO } from '../../../utils/images/Images'
import { SiteName, errorStyle } from '../../../utils/strings/Strings'
import { signIn } from './../../../services/api/api-services';
import toast from 'react-hot-toast';

export default function Login({ isAuth }) {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [disable, setDisable] = useState(false)

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (email === "" || password === "") {
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
        } else {
            handleSubmitForm()
        }
    }

    const handleSubmitForm = () => {
        const data = { email, password }
        setDisable(true)
        const toastId = toast.loading('Logging...');
        signIn(data).then((response) => {
            if (response.err === false) {
                localStorage.setItem("token", response?.token);
                isAuth && isAuth()
                toast.dismiss(toastId);
                toast.success("Logging successfully!");
                setDisable(false)
            } else {
                setDisable(false)
                toast.error(response?.msg);
                toast.dismiss(toastId);
            }
        }).catch((err) => {
            console.log(err);
        })

    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (email !== "") {
            setEmailError(false)
        }
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if (password !== "") {
            setPasswordError(false)
        }
    }
    const handlePasswordShow = () => setShowPassword(!showPassword)

    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content '>
                <div className='nk-block nk-block-middle nk-auth-body  wide-xs'>
                    <div className='brand-logo pb-4 text-center'>
                        <Link to={'/'} className='logo-link'>
                            {/* <img
                                className='logo-light logo-img logo-img-lg'
                                src={LOGO}
                                alt='logo' />
                            <img
                                className='logo-dark logo-img logo-img-lg'
                                src={LOGO}
                                alt='logo-dark' /> */}
                            <h2 className="text-dark logo-img">Card Connect</h2>
                            <h2 className="text-white logo-img">Card Connect</h2>

                        </Link>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className='nk-block-head'>
                                <div className='nk-block-head-content'>
                                    <h4 className='nk-block-title'>Login</h4>
                                    <div className='nk-block-des'>
                                        <p>
                                            Access the {SiteName} panel using your email and password.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form autoComplete='off' id='login-form' onSubmit={handleFormValidation}>
                                <div className='form-group'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='email'>
                                            Email address
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <input
                                            style={emailError ? errorStyle : {}}
                                            type='text'
                                            className='form-control'
                                            id='email'
                                            placeholder='Enter your email address or username'
                                            onChange={handleEmailChange}
                                            value={email}
                                            autoComplete='off'
                                            role='presentation'
                                        />
                                        {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='password'>
                                            Password
                                        </label>
                                        <Link className='link link-primary link-sm' to={'/forgot-password'}>Forgot Password?</Link>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                            <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                                        </a>
                                        <input
                                            role="presentation"
                                            autoComplete="new-password"
                                            style={passwordError ? errorStyle : {}}
                                            type={showPassword ? "text" : 'password'}
                                            className='form-control'
                                            id='password'
                                            onChange={handlePasswordChange}
                                            placeholder='Enter your password'
                                            value={password} />
                                        {passwordError && <small className='invalid-feedback d-block'>Password must be filled out.</small>}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <button disabled={disable} type='submit' className='btn  btn-primary btn-block'>
                                        Login
                                    </button>
                                </div>
                            </form>
                            <div className='form-note-s2 text-center pt-4'>
                                New on our platform? <Link to={'register'}>Create an account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
