import React, { useEffect, useState } from 'react'
import ParseJwt from '../../../../../services/parseJwt/parseJwt';
import { useDispatch, useSelector } from 'react-redux';
import { selectVCardData, vCardDataAdditional, vCardData as vCardDataSetup } from '../../../../../redux/action/cardData';

export default function CardLaunch() {
  const vCardData = useSelector(selectVCardData)
  const [userId, setUserId] = useState("")
  const [isDownload, setDownload] = useState(vCardData?.isDownload);
  const [isLocation, setLocation] = useState(vCardData?.isLocation)
  const dispatch = useDispatch()

  const getUserId = () => {
    let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
    setUserId(decodedToken?._id._id)
  }
  const handleDownloadButton = () => {
    setDownload(!isDownload);
  }
  const handleLocationButton = () => {
    setLocation(!isLocation)
  }
  useEffect(() => {
    getUserId();
    dispatch(vCardDataAdditional({ isDownload: isDownload, isLocation: isLocation }));
  }, [isDownload, isLocation])
  return (
    <div className="card">
      <div className="card-body">
        <div className="preview-block">
          <div className="row gy-4">
            <div className="col-sm-7">
              <div className='form-group'>
                <label>URL Domain</label> <br />
                <small>Select a domain for your card.</small>
                <div className='mt-2'>
                  <div className="custom-control custom-switch">
                    <input checked disabled type="checkbox" className="custom-control-input " id="customSwitch1" />
                    <label className="custom-control-label" htmlFor="customSwitch1"></label><small>Auto Generate</small>
                  </div>
                  <div className='form-control-wrap input-group-append align-items-center mt-2'>
                    <select disabled className='form-control'>
                      <option>e-card-qr-code-user</option>
                    </select>
                    <div style={{ fontSize: 20, margin: "0px 10px" }}>/</div>
                    <input type="text" className='form-control' disabled value={userId} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <h6>Advance Settings</h6>
              <h6>Download on scan</h6>
              <small>Directly download contact on scan.</small><br />
              <div className="custom-control custom-switch mt-2">
                <input checked={isDownload} onChange={handleDownloadButton} type="checkbox" className="custom-control-input " id="download-scan" />
                <label className="custom-control-label" htmlFor="download-scan"></label><small>Enable</small>
              </div>
            </div>
            <div className="col-sm-7">
              <h6>GPS location</h6>
              <small>Track location of viewers.</small><br />
              <div className="custom-control custom-switch mt-2">
                <input checked={isLocation} onChange={handleLocationButton} type="checkbox" className="custom-control-input " id="gps-location" />
                <label className="custom-control-label" htmlFor="gps-location"></label><small>Enable</small>
              </div>
            </div>
            {/* <div className="col-sm-12">
              <button onClick={handleSubmitForm} className="btn btn-primary">Finish</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
