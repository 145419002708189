import React, { useEffect, useState } from 'react'
import { LOGO } from '../../../utils/images/Images'
import { Link, useNavigate } from 'react-router-dom'
import { SiteName, errorStyle } from '../../../utils/strings/Strings'
import { toast } from 'react-hot-toast';
import { signUp } from '../../../services/api/api-services';


export default function Register() {
    const navigation = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (firstName === "" || lastName === "" || email === "" || password === "") {
            if (firstName === "") {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName === "") {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
            return
        } else {
            handleFormSubmit();
        }
    }

    const handleFormSubmit = () => {

        const data = {
            firstName,
            lastName,
            email,
            password
        }
        const toastId = toast.loading('Registering...');
        signUp(data).then((response) => {
            if (response?.err === false) {
                localStorage.setItem("token", response?.token);
                navigation('success');
                toast.dismiss(toastId);
                toast.success("Account created successfully!");
                resetStates();
            } else {
                toast.error(response?.msg);
                toast.dismiss(toastId);
            }
        }).catch((err) => console.log(err));
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        if (firstName !== "") {
            setFirstNameError(false)
        }
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        if (lastName !== "") {
            setLastNameError(false)
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        if (email !== "") {
            setEmailError(false)
        }
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
        if (password !== "") {
            setPasswordError(false)
        }
    }
    const handlePasswordShow = () => setShowPassword(!showPassword)

    const resetStates = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        document.querySelector('form').reset()
    }

    return (
        <div className='nk-block nk-block-middle nk-auth-body wide-sm'>
            <div className='brand-logo pb-4 text-center'>
                <Link to={'/'} className='logo-link'>
                    {/* <img
                        className='logo-light logo-img logo-img-lg'
                        src={LOGO}
                        alt='logo' />
                    <img
                        className='logo-dark logo-img logo-img-lg'
                        src={LOGO}
                        alt='logo-dark' /> */}
                    <h2 className="text-dark logo-img">Card Connect</h2>
                    <h2 className="text-white logo-img">Card Connect</h2>

                </Link>
            </div>
            <div className='card card-bordered'>
                <div className='card-inner card-inner-lg'>
                    <div className='nk-block-head'>
                        <div className='nk-block-head-content'>
                            <h4 className='nk-block-title'>Register</h4>
                            <div className='nk-block-des'>
                                <p>
                                    Create New {SiteName} Account
                                </p>
                            </div>
                        </div>
                    </div>
                    <form autoComplete='off' onSubmit={handleFormValidation}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='first-name'>
                                            First Name
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <input
                                            style={firstNameError ? errorStyle : {}}
                                            type='text'
                                            className='form-control'
                                            id='first-name'
                                            autoComplete='off'
                                            role='presentation'
                                            placeholder='Enter your first name'
                                            onChange={handleFirstNameChange}
                                            value={firstName}
                                        />
                                        {firstNameError && <small className='invalid-feedback d-block'>first name must be filled out.</small>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='last-name'>
                                            Last Name
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <input
                                            style={lastNameError ? errorStyle : {}}
                                            type='text'
                                            className='form-control'
                                            id='last-name'
                                            autoComplete='off'
                                            role='presentation'
                                            placeholder='Enter your last name'
                                            onChange={handleLastNameChange}
                                            value={lastName}
                                        />
                                        {lastNameError && <small className='invalid-feedback d-block'>Last name must be filled out.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-group'>
                            <div className='form-label-group'>
                                <label className='form-label' htmlFor='email'>
                                    Email
                                </label>
                            </div>
                            <div className='form-control-wrap'>
                                <input
                                    style={emailError ? errorStyle : {}}
                                    type='email'
                                    className='form-control'
                                    id='email'
                                    autoComplete='off'
                                    role='presentation'
                                    placeholder='Enter your email address'
                                    onChange={handleEmailChange}
                                    value={email} />
                                {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className='form-label-group'>
                                <label className='form-label' htmlFor='password'>
                                    Password
                                </label>
                            </div>
                            <div className='form-control-wrap'>
                                <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                    <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                                </a>
                                <input
                                    role="presentation"
                                    autoComplete="new-password"
                                    style={passwordError ? errorStyle : {}}
                                    type={showPassword ? "text" : 'password'}
                                    className='form-control'
                                    id='password'
                                    onChange={handlePasswordChange}
                                    placeholder='Enter your password'
                                    value={password} />
                                {passwordError && <small className='invalid-feedback d-block'>Password must be filled out.</small>}
                            </div>
                        </div>
                        <div className='form-group'>
                            <button type='submit' className='btn btn-primary btn-block'>
                                Register
                            </button>
                        </div>
                    </form>
                    <div className='form-note-s2 text-center pt-4'>
                        Already have an account? <Link to={'/'}> Sign in instead</Link>
                    </div>
                    {/* <div className='text-center pt-4 pb-3'>
                                <h6 className='overline-title overline-title-sap'><span>OR</span></h6>
                            </div>
                            <ul className='nav justify-center gx-4'>
                                <li className='nav-item'>
                                    <a className='nav-link' href='#'>Facebook</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link' href='#'>Google</a>
                                </li>
                            </ul> */}
                </div>
            </div>
        </div>
    )
}
