import React from 'react'
import { Outlet } from 'react-router-dom'

export default function ProfileLayout() {
    return (
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <div class="nk-block">
                        <div class="card">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
