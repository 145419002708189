import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';

class GoogleMapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlace: {}
        }
    }
    onMapClicked = (event) => {
        console.log(this.props.markers);
    }



    render() {
        return (
            <Map google={this.props.google} zoom={4}
                containerStyle={{
                    width: '100%',
                    height: 450,
                    position: 'relative'
                }}
                onClick={this.onMapClicked}

                centerAroundCurrentLocation={true}
                center={this.props.markers[this.props.markers.length - 1]}
            >
                {this.props.markers?.map((item, index) => <Marker position={item} onClick={this.onMarkerClick} name={'Current location'} />)}
                {/* <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                        <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        )
    }
}


export default GoogleApiWrapper({
    apiKey: "AIzaSyDXryY-RrI6NpeeZi88FA0iX0A_QVk5fjE"
})(GoogleMapContainer)