import React, { useCallback, useEffect } from 'react'

export default function MultipleInputs({ data, setData, dataObject, label, name, }) {
    const handleMultiple = (event, index) => {
        let { name, value } = event.target;
        // let jasper = Object.assign({}, ...data);
        // let onChangeValue = [...data];
        // jasper[index][name] = value;
        // onChangeValue[index][name] = value;

        const newState = data.map((obj, i) => {
            // 👇️ if id equals 2, update country property

            if (index === i && name !== "type") {
                return { ...obj, value: value, };
            }
            if (index === i && name === "type") {
                return { ...obj, type: value, };
            }

            // 👇️ otherwise return the object as is
            return obj;
        });
        setData(newState)

    }

    const handleAdd = () => {
        setData((prev) => [...prev, dataObject])
    }
    const handleDelete = (i) => {
        if (data.length === 1) {
            return
        }
        const deleteVal = [...data];

        deleteVal.splice(i, 1);
        setData(deleteVal)
    }

    useEffect(() => {

    }, [])

    return (
        <div className="col-sm-12">
            <label className="form-label" htmlFor={name}>{label}</label>
            {data?.map((item, index) => (
                <div div className="row g-2 mb-2" key={index.toString()} >
                    <div className="col-sm-5">
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <input
                                    onChange={(event) => handleMultiple(event, index)}
                                    name={name}
                                    value={item?.value}
                                    type="text" className="form-control"
                                    id={name} placeholder={label} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="form-group input-group-append">
                            <select name="type" className="form-control me-2" onChange={(event) => handleMultiple(event, index)}>
                                <option value={"work"}>Work</option>
                                <option value={"office"}>Office</option>
                                <option value={"other"}>Other</option>
                            </select>
                            <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                                <em className='icon ni ni-trash-fill' />
                            </button>
                        </div>
                    </div>
                </div>
            ))
            }
            <div className="col-sm-12">
                <button className="btn p-0" onClick={handleAdd}>
                    <em className='icon ni ni-plus' style={{ fontSize: 15 }} />&nbsp;Add {label}
                </button>
            </div>
        </div >
    )
}
