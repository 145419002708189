import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOGO } from '../../../utils/images/Images'
import { forgotPassword } from '../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../utils/strings/Strings';

export default function ForgotPassword() {
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [isDisabled, setDisabled] = useState(false)
    const handleFormValidation = (event) => {
        event.preventDefault()
        if (email === "") {
            setEmailError(true)
        } else {
            setEmailError(false);
            handleFromSubmit();
        }
    }
    const handleFromSubmit = () => {
        let body = {
            "email": email
        }
        setDisabled(true)
        const toastId = toast.loading('Logging...');
        forgotPassword(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg);
                navigation(`/verify-otp?email=${email}`);
                setDisabled(false);
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch(err => console.log(err))
    }

    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content'>
                <div className='nk-block nk-block-middle nk-auth-body  wide-xs'>
                    <div className='brand-logo pb-4 text-center'>
                        <Link to={'/'} className='logo-link'>
                            {/* <img
                                className='logo-light logo-img logo-img-lg'
                                src={LOGO} alt='logo' />
                            <img
                                className='logo-dark logo-img logo-img-lg'
                                src={LOGO}
                                alt='logo-dark' /> */}
                            <h2 className="text-dark logo-img">Card Connect</h2>
                            <h2 className="text-white logo-img">Card Connect</h2>

                        </Link>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className='nk-block-head'>
                                <div className='nk-block-head-content'>
                                    <h5 className='nk-block-title'>Forgot password</h5>
                                    <div className='nk-block-des'>
                                        <p>
                                            If you forgot your password, well, then we’ll email you instructions to reset your password.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleFormValidation}>
                                <div className='form-group'>
                                    <div className='form-label-group'>
                                        <label className='form-label' htmlFor='default-01'>
                                            Email address
                                        </label>
                                    </div>
                                    <div className='form-control-wrap'>
                                        <input
                                            type='email'
                                            style={emailError ? errorStyle : {}}
                                            className='form-control'
                                            id='default-01'
                                            onChange={(event) => setEmail(event?.target?.value)}
                                            placeholder='Enter your email address' />
                                        {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <button disabled={isDisabled} type='submit' className='btn  btn-primary btn-block'>
                                        Send OTP
                                    </button>
                                </div>
                            </form>
                            <div className='form-note-s2 text-center pt-4'>
                                <Link to={'/'}><strong>Return to login</strong></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
