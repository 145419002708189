import React, { useEffect, useLayoutEffect, useState } from "react";
import JsxParser from "react-jsx-parser";
import { useSelector } from "react-redux";
import { selectVCardData } from "../../../../redux/action/cardData";
import QRCode from "react-qr-code";
import ParseJwt from "../../../../services/parseJwt/parseJwt";
import { QRCodeSVG } from "qrcode.react";
import { PREVIEW_CARD_URL } from '../../../../utils/strings/Strings';
export default function Output({ isShow, onClose, innerHtml, cardId, viewCount }) {
    const vCardData = useSelector(selectVCardData)
    const [activeCard, setActiveCard] = useState("card");
    const [qrCode, setQrCode] = useState("")
    const [userData, setUserData] = useState([])


    useEffect(() => {
        let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
        setUserData(decodedToken?._id)
        generateQrCode()
    }, [cardId])

    const handleActiveCard = (type) => {
        setActiveCard(type)
    }
    const generateQrCode = () => {
        setQrCode(`${PREVIEW_CARD_URL}${cardId}`)
        //setQrCode(`https://instagram.com`)
    }
    const onImagedownload = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
 
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    const handleQRCodeScan = () => {
        window.open(qrCode, '_blank');
    };

    return (
        <>
            <div className={`nk-demo-panel nk-demo-panel-2x toggle-slide  toggle-slide-right toggle-screen-any overflow-auto ${isShow ? 'content-active' : ''}`}>
                <div className="nk-demo-head">
                    <h6 className="mb-0">Use Case Concept</h6>
                    <a
                        className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active"
                        onClick={onClose && onClose}
                    >
                        <em className="icon ni ni-cross" />
                    </a>
                </div>
                <div className="nk-demo-list" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{ margin: 0 }}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer" />
                        </div>
                        <div className="container-fluid">
                            <div className="final-card-tabs-container">
                                <button onClick={() => handleActiveCard("card")} style={{ background: activeCard === 'card' ? "#fff" : "transparent" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={12}>
                                        <path fill={activeCard === 'card' ? "#4141FF" : "#585858"} d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zm96 320h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM144 64h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                    </svg>
                                </button>
                                <button onClick={() => handleActiveCard("qr-code")} style={{ background: activeCard === 'qr-code' ? "#fff" : "transparent" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={12}>
                                        <path fill={activeCard === 'qr-code' ? "#4141FF" : "#585858"} d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM64 96v64h64V96H64zM0 336c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336zm64 16v64h64V352H64zM304 32h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48zm80 64H320v64h64V96zM256 304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V304zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" />
                                    </svg>
                                </button>
                            </div>
                            {activeCard === 'card' && <div className="w-100 card-container">
                                <div className="" style={{ marginTop: 10 }}>
                                    <JsxParser
                                        // onError={(e) => console.log(e)}
                                        showWarnings
                                        bindings={{
                                            socialMediaLinks: vCardData?.socialMediaLinks,
                                            customLinks: vCardData?.customLinks,
                                            emails: vCardData?.emails,
                                            phones: vCardData?.phones,
                                            name: vCardData?.firstName ? (vCardData?.firstName + " " + vCardData?.lastName) : "Alex Walker",
                                            designation: vCardData?.designation ? vCardData?.designation : "Crative Directore",
                                            userImage: vCardData?.userImagePreview ? vCardData?.userImagePreview : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
                                            logo: vCardData?.logoImagePreview ? vCardData?.logoImagePreview : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/ZDF_logo%21_Logo_2021.svg/1200px-ZDF_logo%21_Logo_2021.svg.png",
                                            location: vCardData?.location ? vCardData?.location : "Lorem ipsum dolor sit amet.",
                                            prefix: vCardData?.prefix,
                                            suffix: vCardData?.suffix,
                                            department: vCardData?.department,
                                            pronouns: vCardData?.pronouns,
                                            company: vCardData?.company,
                                            summary: vCardData?.summary ? vCardData?.summary : "Lorem ipsum dolor sit amet.",
                                            primaryColor: vCardData?.primaryColor,
                                            profileTextColor: vCardData?.profileTextColor,
                                            secondaryColor: vCardData?.secondaryColor,
                                            titleFontSize: vCardData?.titleFontSize,
                                            website: vCardData?.website,
                                            fontFamily: vCardData?.fontFamily

                                        }}
                                        jsx={innerHtml}
                                    />
                                </div>
                                <div className="details">
                                    <h6>Details</h6>
                                    <ul>
                                        <li><strong>Link:</strong> {`${PREVIEW_CARD_URL}${cardId}`}<a href={`${PREVIEW_CARD_URL}${cardId}`} className="ms-2" target="_blank"><em class="icon ni ni-eye-fill"></em></a> <a href="" className="ms-2"><em class="icon ni ni-copy"></em></a> </li>
                                        <li><strong>Views:</strong> {viewCount} </li>
                                        <li><strong>Owner:</strong> {userData?.email}</li>
                                    </ul>
                                </div>

                            </div>}
                            {activeCard === 'qr-code' && <div className="w-100 qr-code-container h-100">
                                <div className="" style={{ height: '60vh', marginTop: 10, textAlign: 'center' }}>
                                    <div style={{ background: 'white', padding: '16px',display:"flex",justifyContent:"center",alignItems:"center" }}>
                                        <QRCodeSVG id="QRCode" size={window.innerWidth * 0.6}
                                            // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            style={{backgroundColor:"white",display:"flex",justifyContent:"center",alignItems:"center"}}
                                            value={qrCode}
                                            renderAs="canvas"
                                            height={300}
                                            width={350}
                                            onClick={handleQRCodeScan}
                                           
                                        />,
                                    </div>
                                    <button onClick={onImagedownload} className="btn btn-primary">Download Code</button>
                                </div>
                                <div className="details">
                                    <h6>Details</h6>
                                    <ul>
                                        <li><strong>Link:</strong> {`${PREVIEW_CARD_URL}${cardId}`}<a href={`${PREVIEW_CARD_URL}${cardId}`} className="ms-2" target="_blank"><em class="icon ni ni-eye-fill"></em></a><a href="" className="ms-2"><em class="icon ni ni-copy"></em></a> </li>
                                        <li><strong>Views:</strong> {viewCount} </li>
                                        <li><strong>Owner:</strong> {userData?.email}</li>
                                    </ul>
                                </div>
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
            {isShow && <div className="toggle-overlay" data-target="demoUC" onClick={onClose && onClose}></div>}

        </>
    );
}
