import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
    CardAnalytics, ForgotPassword, LoginPage, RegisterPage,
    SuccessPage, Analytics, VerifyOtp, ResetPassword,
    Profile, Setting
} from './routes/routes'
import Container from './components/layouts/Container';
import './assets/css/dashlite583f.css?ver=3.1.3'
import './assets/css/theme583f.css?ver=3.1.3'
import AuthLayout from './components/layouts/AuthLayout';
import RegisterLayout from '../src/pages/auth/register/RegisterLayout'
import MyCardTable from './pages/dashboard/digital-business-card/digital-business-cards/MyCardTable';
import CardDetailsLayout from './pages/dashboard/digital-business-card/digital-business-card-detail/CardDetailsLayout';
import CardLayout from './pages/dashboard/digital-business-card/digital-business-card-layout/CardLayout';
import ProfileLayout from './components/layouts/ProfileLayout';


export default function App() {
    const [isValid, setIsValid] = useState(false);
    const navigation = useNavigate();
    const handleValidForm = () => {
        const token = localStorage.getItem('token');
        if (token !== null) {
            setIsValid(true);
        } else {
            setIsValid(false);
            navigation('/');
        }
    }

    useEffect(() => {
        handleValidForm()
        return () => { }
    }, [])

    return (
        !isValid ?
            <Routes>
                <Route path='/' Component={AuthLayout}>
                    <Route index element={<LoginPage isAuth={handleValidForm} />} />
                    <Route path='register' Component={RegisterLayout}>
                        <Route index Component={RegisterPage} />
                        <Route path='success' element={<SuccessPage isAuth={handleValidForm} />} />
                    </Route>
                    <Route path='/forgot-password' Component={ForgotPassword} />
                    <Route path='/verify-otp' Component={VerifyOtp} />
                    <Route path='/reset-password' Component={ResetPassword} />
                </Route>
            </Routes> :
            <Routes>
                <Route path="/" element={<Container isAuth={handleValidForm} />}>
                    <Route index Component={Analytics} />
                    <Route path='/card-analytics/:cardId' Component={CardAnalytics} />
                    <Route path="my-cards" Component={MyCardTable} />
                    <Route path="/card-layouts" Component={CardLayout} />
                    <Route path='/add/:layoutId' Component={CardDetailsLayout} />
                    <Route path='/update/:cardId' Component={CardDetailsLayout} />
                    <Route path='/profile' element={<ProfileLayout />}>
                        <Route index Component={Profile} />
                        <Route path='setting' Component={Setting} />
                    </Route>
                </Route>
            </Routes>
    )
}
