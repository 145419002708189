import React, { useEffect, useState } from 'react'
import { getTemplate } from '../../../../services/api/api-services';
import Pagination from "react-js-pagination";
import { SiteName } from '../../../../utils/strings/Strings';
import { Link } from 'react-router-dom';

export default function CardLayout() {

    const [template, setTemplate] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [totalTemplate, setTotalTemplate] = useState(0)
    const [activePage, setActivePage] = useState(1)

    const handleGetTemplate = (pageNumber) => {
        getTemplate(pageNumber).then((response) => {
            if (response?.err === false) {
                if (response?.templates?.length !== 0) {
                    setTemplate(response?.templates);
                    setTotalTemplate(response?.count)
                    setEmpty(false)
                } else {
                    setEmpty(true)
                }
            }
        }).catch((err) => console.log(err))
    }
    useEffect(() => {
        handleGetTemplate(activePage)
    }, [])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    return (
        <div className='container'>
            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='nk-block-head nk-block-head-sm pb-2'>
                        <div className='nk-block-between g-3'>
                            <div className='nk-block-head-content'>
                                <h3 className='nk-block-title page-title'>Select from {SiteName} templates</h3>
                                <div className='nk-block-des text-soft'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='nk-block mt-0'>
                        <div className='row g-gs'>
                            {template?.length > 0 ? template?.map((item, index) => (
                                <div className='col-md-4 col-lg-3 col-xxl-3' key={index.toString()}>
                                    <Link to={`/add/${item?._id}`} state={{ cardData: item }} className='card card-bordered product-card'>
                                        <div className='product-thumb'>
                                            <div>
                                                <img className='card-img-top' src={item?.file} alt={index?.toString()} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )) : isEmpty ? <Link to={'/add/ahs1233hm'} className='text-center'>No card found !</Link> : <h3 className='text-center'>Loading....</h3>}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalTemplate}
                            pageRangeDisplayed={5}
                            innerClass='pagination pagination-sm'
                            itemClass='page-item'
                            linkClass='page-link'
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
