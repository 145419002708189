import React, { memo, useEffect, useState } from "react";
import FileInput from "../../../../../components/fileinput/FileInput";
import { socialMediaMetaData } from "../../../../../utils/socialmedia/socialmedita";
import { useDispatch, useSelector } from "react-redux";
import { selectVCardData, vCardData as vCardSetup } from '../../../../../redux/action/cardData'
import MultipleInputs from "../multipleInputs/MultipleInputs";
import { createCardSetup } from "../../../../../services/api/api-services";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ParseJwt from "../../../../../services/parseJwt/parseJwt";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
function CardSetup({ userImageData, logoImageData, dataLoaded, }) {

  const dispatch = useDispatch()
  const vCardData = useSelector(selectVCardData)

  const initialPhones = vCardData?.phones?.length > 0 ? vCardData?.phones : []

  const initialEmails = vCardData?.emails?.length > 0 ? vCardData?.emails : []

  const initialWebsite = vCardData?.website?.length > 0 ? vCardData?.website : []

  const initialCustomLinks = vCardData?.customLinks?.length > 0 ? vCardData?.customLinks : []

  let [phoneNumber, setPhoneNumber] = useState(initialPhones);

  let [emailAddress, setEmailAddress] = useState(initialEmails);

  let [website, setWebsite] = useState(initialWebsite);

  let [customLinks, setCustomLinks] = useState(initialCustomLinks);

  const [userImagePreview, setUserImagePreview] = useState(vCardData?.userImagePreview);
  const [logoImagePreview, setLogoImagePreview] = useState(vCardData?.logoImagePreview);
  const [firstName, setFirstName] = useState(vCardData?.firstName);
  const [lastName, setLastName] = useState(vCardData?.lastName);
  const [prefix, setPrefix] = useState(vCardData?.prefix);
  const [suffix, setSuffix] = useState(vCardData?.suffix);
  const [designation, setDesignation] = useState(vCardData?.designation);
  const [department, setDepartment] = useState(vCardData?.department);
  const [pronouns, setPronouns] = useState(vCardData?.pronouns);
  const [company, setCompany] = useState(vCardData?.company);
  const [summary, setSummary] = useState(vCardData?.summary);

  const [logo, setLogo] = useState(vCardData?.logoImagePreview);
  const [userImage, setUserImage] = useState(vCardData?.userImagePreview);


  const [location, setLocation] = useState(vCardData?.location);
  const [socialMediaLinks, setSocialMediaLinks] = useState(vCardData?.socialMediaLinks)

  const [userId, setUserId] = useState();
  const { layoutId } = useParams()

  const getUserId = () => {
    let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
    setUserId(decodedToken?._id._id)
  }

  const onChangeUserImage = (event) => {
    let image = URL.createObjectURL(event.target.files[0]);
    setUserImage(event.target.files[0]);
    setUserImagePreview(image)
  }

  const onChangeCompanyLogo = (event) => {
    if (event.target.files && event.target.files[0]) {
      let image = URL.createObjectURL(event.target.files[0]);
      setLogo(event.target.files[0]);
      setLogoImagePreview(image)
    }
  }

  const handleAddSocialMedia = (data, index) => {
    setSocialMediaLinks([...socialMediaLinks, {
      logo: data?.logo,
      title: data?.title,
      link: "",
      index: index,

    }])
  }

  const handleSocialMediaLinks = (event, index) => {
    const { name, value } = event.target
    const onChangeValue = [...socialMediaLinks];
    onChangeValue[index][name] = value;
    setSocialMediaLinks(onChangeValue)
  }

  const handleSocialMediaLinkDelete = (index) => {
    const deleteVal = [...socialMediaLinks];

    deleteVal.splice(index, 1);
    setSocialMediaLinks(deleteVal)
  }

  const isInclude = (title) => socialMediaLinks.find(x => x.title === title)

  const getSocialLogo = (title) => socialMediaMetaData.find(x => x.title === title)
  useEffect(() => {
    getUserId();

    dispatch(vCardSetup({
      firstName,
      lastName,
      prefix,
      suffix,
      designation,
      department,
      pronouns,
      company,
      summary,
      location,
      logoImagePreview,
      userImagePreview,
      phones: phoneNumber,
      emails: emailAddress,
      website: website,
      customLinks: customLinks,
      socialLinks: socialMediaLinks,
    }));

    userImageData(userImage);
    logoImageData(logo);
  }, [firstName, lastName, prefix, suffix, designation, department, pronouns, company, summary, phoneNumber, emailAddress, website, customLinks, location, socialMediaLinks, userImage, logo, dataLoaded])

  console.log(phoneNumber);

  return (
    <div className="card">
      <div className="card-body">
        <div className="preview-block">
          <h4>Profile Information</h4>
          <span className="preview-title-lg">
            Add your profile information to show in your digital business card
          </span>
          <div className="row gy-4">
            <div className="col-sm-12">
              <div className="form-group">
                <div className="form-label">User Image</div>
                <label className="user-image" htmlFor="file-input">
                  <img src={userImagePreview ? userImagePreview : "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"} />
                  <input
                    onChange={onChangeUserImage}
                    type="file"
                    className="d-none"
                    id="file-input"
                    name="userImage"
                  />
                  <span className="user-image-pen">
                    <em className="icon ni ni-edit-alt-fill"></em>
                  </span>
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="first-name">
                  First name <span className="text-danger">*</span>
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setFirstName(event.target.value)}
                    name="firstName"
                    value={firstName}
                    type="text"
                    className="form-control"
                    id="first-name"
                    placeholder="First name"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="last-name">
                  Last name
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setLastName(event.target.value)}
                    name="lastName"
                    value={lastName}
                    type="text"
                    className="form-control"
                    id="last-name"
                    placeholder="Last name"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="prefix">
                  Prefix
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setPrefix(event.target.value)}
                    name="prefix"
                    value={prefix}
                    type="text"
                    className="form-control"
                    id="prefix"
                    placeholder="Prefix"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="suffix">
                  Suffix
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setSuffix(event.target.value)}
                    name="suffix"
                    value={suffix}
                    type="text"
                    className="form-control"
                    id="suffix"
                    placeholder="Suffix"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="designation">
                  Designation
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setDesignation(event.target.value)}
                    name="designation"
                    type="text"
                    value={designation}
                    className="form-control"
                    id="designation"
                    placeholder="Designation"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="department">
                  Department
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setDepartment(event.target.value)}
                    name="department"
                    type="text"
                    value={department}
                    className="form-control"
                    id="department"
                    placeholder="Department"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="pronouns">
                  Pronouns
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setPronouns(event.target.value)}
                    name="pronouns"
                    value={pronouns}
                    type="text"
                    className="form-control"
                    id="pronouns"
                    placeholder="pronouns"
                  />
                </div>
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="company">
                  Company
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(event) => setCompany(event.target.value)}
                    name="company"
                    value={company}
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder="Company"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="summary">
                  Summary
                </label>
                <div className="form-control-wrap">
                  <textarea
                    onChange={(event) => setSummary(event.target.value)}
                    name="summary"
                    value={summary}
                    type="text"
                    className="form-control"
                    id="summary"
                    placeholder="Summary"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="company-logo">
                  Company logo
                </label>
                <FileInput inputName={'logo'} onChange={onChangeCompanyLogo} isPreview value={() => console.log()} />
              </div>
            </div>
            <div className="col-sm-12">
              <h4>Contact Details</h4>
              <p>Add contact information.</p>
            </div>
            <MultipleInputs
              data={phoneNumber.length > 0 ? phoneNumber : [{
                value: "",
                type: "work"
              }]}
              dataObject={{
                value: "",
                type: "work"
              }}
              setData={(data) => {
                setPhoneNumber(data)
              }}
              name={"value"}
              label={"Phone"}
            />
            <MultipleInputs
              data={emailAddress.length > 0 ? emailAddress : [{
                value: "",
                type: "work"
              }]}
              dataObject={{
                value: "",
                type: "work"
              }}
              setData={(data) => setEmailAddress(data)}
              name={"value"}
              label={"Email address"}
            />
            <MultipleInputs
              data={website.length > 0 ? website : [{
                value: "",
                type: "work"
              }]}
              dataObject={{
                value: "",
                type: "work"
              }}
              setData={(data) => setWebsite(data)}
              name={"value"}
              label={"Website"}
            />
            <MultipleInputs
              data={customLinks.length > 0 ? customLinks : [{
                value: "",
                type: "work"
              }]}
              dataObject={{
                value: "",
                type: "work"
              }}
              setData={(data) => setCustomLinks(data)}
              name={"value"}
              label={"Custom Links"}
            />
            <div className="col-sm-12">
              <label className="form-label" htmlFor="custom-field">Location</label>

              <div className="form-group">
                <div className="form-control-wrap">
                  <input value={location} type="text" className="form-control" id="custom-field" name="location" placeholder="Location" onChange={(event) => setLocation(event.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <h4>Social Links</h4>
              <p>Add links to let people connect with you.</p>
              <div className="social-links-container">
                {socialMediaMetaData?.map((item, index) => (
                  <button disabled={isInclude(item?.title)?.title === item?.title} className={`social-links`} key={index.toString()} onClick={() => handleAddSocialMedia(item, index)}
                    style={{ borderColor: isInclude(item?.title)?.title === item?.title ? "#328df9" : "" }}
                  >
                    <img src={process.env.PUBLIC_URL + item.logo} />
                  </button>
                ))}
              </div>
              {socialMediaLinks.map((item, index) => (
                <>
                  <label className="form-label" htmlFor={item?.title}>{item?.title}</label>
                  <div className="row g-2 mb-2">
                    <div className="col-sm-6">
                      <div className="d-flex align-center">
                        <img src={getSocialLogo(item.title).logo} className="social-links mb-0" />
                        <div className="form-group input-group-append">
                          <input
                            onChange={(event) => handleSocialMediaLinks(event, index)}
                            name={'link'}
                            value={item?.link}
                            type="text" className="form-control me-2" id={'link'} placeholder={item?.title} />
                          <button className="btn btn-danger" onClick={() => handleSocialMediaLinkDelete(index)}>
                            <em className='icon ni ni-trash-fill' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CardSetup)