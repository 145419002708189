import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { LOGO } from '../../../utils/images/Images';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { resetPassword } from '../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../utils/strings/Strings';
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function ResetPassword() {
    const navigation = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    let query = useQuery();

    const handleOtpValidation = (event) => {
        event.preventDefault();
        if (password === "" || confirmPassword === "") {
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false);
            }
            if (confirmPassword === "") {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false);
            }
            if (password !== confirmPassword) {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false)
            }
            return
        } else {
            handleFormSubmit();
        }

    }

    const handleFormSubmit = () => {
        let body = {
            "email": query.get("email"),
            "password": password
        }
        const toastId = toast.loading("Loading...")
        setDisabled(true)
        resetPassword(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg)
                navigation('/')
                setDisabled(false)
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        if (!query.get("email")) {
            navigation('/')
        }
    }, [])


    const handleConfirmPassword = (event) => {
        setConfirmPassword(event?.target?.value)
        if (password === event?.target?.value) {
            setConfirmPasswordError(false)
        } else {
            setConfirmPasswordError(true)
        }
    }
    const handlePasswordShow = () => setShowPassword(!showPassword)
    return (
        <div className='nk-wrap nk-wrap-nosidebar'>
            <div className='nk-content'>
                <div className='nk-block nk-block-middle nk-auth-body wide-xs'>
                    <div className="brand-logo pb-4 text-center">
                        <Link to={'/'} className="logo-link">
                            {/* <img className="logo-light logo-img logo-img-lg" src={LOGO} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LOGO} alt="logo-dark" /> */}
                            <h2 className="text-dark logo-img">Card Connect</h2>
                            <h2 className="text-white logo-img">Card Connect</h2>

                        </Link>
                    </div>
                    <div className='card card-bordered'>
                        <div className='card-inner card-inner-lg'>
                            <div className="nk-block-head">
                                <form className="nk-block-head-content" onSubmit={handleOtpValidation}>
                                    <h4 className="nk-block-title">Reset password</h4>
                                    <p className='mb-0'>Create new password</p>
                                    <p className='mb-4'><b>{query.get("email")}</b></p>
                                    <div className='form-group'>
                                        <div className='form-label-group'>
                                            <label className='form-label' htmlFor='default-01'>
                                                New Password
                                            </label>
                                        </div>
                                        <div className='form-control-wrap'>
                                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                                            </a>
                                            <input
                                                type={showPassword ? "text" : 'password'}
                                                style={passwordError ? errorStyle : {}}
                                                className='form-control'
                                                id='default-01'
                                                onChange={(event) => setPassword(event?.target?.value)}
                                                placeholder='Enter your email address' />
                                            {passwordError && <small className='invalid-feedback d-block'>Password must be filled out.</small>}
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='form-label-group'>
                                            <label className='form-label' htmlFor='default-01'>
                                                Confirm Password
                                            </label>
                                        </div>
                                        <div className='form-control-wrap'>
                                            <input
                                                type='password'
                                                style={confirmPasswordError ? errorStyle : {}}
                                                className='form-control'
                                                id='default-01'
                                                onChange={handleConfirmPassword}
                                                placeholder='Enter your email address' />
                                            {confirmPasswordError && <small className='invalid-feedback d-block'>Confirm password do not match.</small>}
                                        </div>
                                    </div>
                                    <button type='submit' disabled={isDisabled} className='btn btn-primary btn-block'>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
